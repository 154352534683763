import * as _tslib2 from "tslib";

var _tslib = "default" in _tslib2 ? _tslib2.default : _tslib2;

import _types from "./types";
import _load from "./load";
import _static from "./static";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.root = exports.parseHTML = exports.merge = exports.contains = void 0;
var tslib_1 = _tslib;
/**
 * Types used in signatures of Cheerio methods.
 *
 * @category Cheerio
 */

tslib_1.__exportStar(_types, exports);

tslib_1.__exportStar(_load, exports);

var load_1 = _load;
/**
 * The default cheerio instance.
 *
 * @deprecated Use the function returned by `load` instead.
 */

exports.default = load_1.load([]);

var staticMethods = tslib_1.__importStar(_static);
/**
 * In order to promote consistency with the jQuery library, users are encouraged
 * to instead use the static method of the same name.
 *
 * @deprecated
 * @example
 *
 * ```js
 * const $ = cheerio.load('<div><p></p></div>');
 *
 * $.contains($('div').get(0), $('p').get(0));
 * //=> true
 *
 * $.contains($('p').get(0), $('div').get(0));
 * //=> false
 * ```
 *
 * @returns {boolean}
 */


exports.contains = staticMethods.contains;
/**
 * In order to promote consistency with the jQuery library, users are encouraged
 * to instead use the static method of the same name.
 *
 * @deprecated
 * @example
 *
 * ```js
 * const $ = cheerio.load('');
 *
 * $.merge([1, 2], [3, 4]);
 * //=> [1, 2, 3, 4]
 * ```
 */

exports.merge = staticMethods.merge;
/**
 * In order to promote consistency with the jQuery library, users are encouraged
 * to instead use the static method of the same name as it is defined on the
 * "loaded" Cheerio factory function.
 *
 * @deprecated See {@link static/parseHTML}.
 * @example
 *
 * ```js
 * const $ = cheerio.load('');
 * $.parseHTML('<b>markup</b>');
 * ```
 */

exports.parseHTML = staticMethods.parseHTML;
/**
 * Users seeking to access the top-level element of a parsed document should
 * instead use the `root` static method of a "loaded" Cheerio function.
 *
 * @deprecated
 * @example
 *
 * ```js
 * const $ = cheerio.load('');
 * $.root();
 * ```
 */

exports.root = staticMethods.root;
export default exports;
export const __esModule = exports.__esModule,
      root = exports.root,
      parseHTML = exports.parseHTML,
      merge = exports.merge,
      contains = exports.contains,
      load = exports.load;